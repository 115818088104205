.carDetailHeading {
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 33.6px;
  margin: 0;
}

.listItem,
.arrayListItem {
  margin: 0px;
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  padding: 0;
  margin-bottom: 5px;
}

.arrayListItem {
  cursor: pointer;
}

.arrayListItem:hover,
.arrayListItem.clicked {
  color: var(--marketplaceColor);
  font-weight: bold;
}

.extraSpecTitle {
  margin-bottom: 8px;
  color: #A1A1A1;
  font-size: 14px;
  font-weight: 500;
}

.extraSpecContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.extraSpecItem {
  padding: 6px 8px;
  border-radius: 6px;
  background: #F3F3F3;
  color: #555;
  font-size: 14px;
  font-weight: 500;
}