.carDetailHeading {
  font-weight: var(--fontWeightMedium);
  font-size: 18px;
  line-height: 33.6px;
  margin: 0;
}

.listItem,
.arrayListItem {
  margin: 0px;
  font-weight: var(--fontWeightRegular);
  font-size: 16px;
  padding: 0;
  margin-bottom: 5px;
}

.arrayListItem {
  cursor: pointer;
}

.arrayListItem:hover,
.arrayListItem.clicked {
  color: var(--marketplaceColor);
  font-weight: bold;
}
